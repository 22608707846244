// Imports
          import InputText from "../../../../components/inputs/InputText";
import InputPhoneNumber from "../../../../components/inputs/inputPhoneNumber/InputPhoneNumber";
import InputTextArea from "../../../../components/inputs/InputTextArea";
import { showModalOK  } from '../../../../components/modal'
import React, { useEffect, useMemo,useContext } from 'react';

import { useState } from 'react';

import '../../../../App.css';
import { useNavigate } from 'react-router-dom';


import * as fa from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { api } from "../../../../service/mftc-api";


function Component82Page() {
const navigate = useNavigate()


const [content,setContent]= useState( {} )   

const setInternal= (key,value)=>{ setContent(c=> ({...c, [key]:value}) );  }

//States


//Events
const bloc0_1_0_1_0x0_onClick = async (e) => {
                try{ 
                      navigate('/home');
                }
                catch(e){
                  console.log("Error bloc0_1_0_1_0x0_onClick ")
                }  
              };

const bloc0_1_0_1_0x1_onClick = async (e) => {
                try{ 
                      navigate('/about');
                }
                catch(e){
                  console.log("Error bloc0_1_0_1_0x1_onClick ")
                }  
              };

const bloc0_1_0_1_0x2_onClick = async (e) => {
                try{ 
                      navigate('/contact');
                }
                catch(e){
                  console.log("Error bloc0_1_0_1_0x2_onClick ")
                }  
              };

const bloc0_1_0_1_0x3_onClick = async (e) => {
                try{ 
                      navigate('/legal');
                }
                catch(e){
                  console.log("Error bloc0_1_0_1_0x3_onClick ")
                }  
              };

const bloc0_1_1_1_0x0_onClick = async (e) => {
                try{ 
                      navigate('/home');
                }
                catch(e){
                  console.log("Error bloc0_1_1_1_0x0_onClick ")
                }  
              };

const bloc0_1_1_1_0x1_onClick = async (e) => {
                try{ 
                      navigate('/about');
                }
                catch(e){
                  console.log("Error bloc0_1_1_1_0x1_onClick ")
                }  
              };

const bloc0_1_1_1_0x2_onClick = async (e) => {
                try{ 
                      navigate('/contact');
                }
                catch(e){
                  console.log("Error bloc0_1_1_1_0x2_onClick ")
                }  
              };

const bloc0_1_1_1_0x3_onClick = async (e) => {
                try{ 
                      navigate('/legal');
                }
                catch(e){
                  console.log("Error bloc0_1_1_1_0x3_onClick ")
                }  
              };

const action_449 =   async ({event,e})=> {
        try { 
        var obj =event.target
while (obj.tagName!=="DIV" && obj) obj = obj.parentNode

obj.nextSibling.classList.remove("hidden");
obj.classList.add("hidden");
obj.parentNode.nextSibling.classList.remove("hidden");
}
        catch (error) {
        console.error(error);
        }
      };  

const action_event_449_bloc0_1_1_0_1 =  async(event)=>{

                            
                           
              
                          await action_449( { event }) 
              
                          };  

const action_450 =   async ({event,e})=> {
        try { 
        var obj =event.target
while (obj.tagName!=="DIV" && obj) obj = obj.parentNode

obj.previousSibling.classList.remove("hidden");

obj.classList.add("hidden");

obj.parentNode.nextSibling.classList.add("hidden");
}
        catch (error) {
        console.error(error);
        }
      };  

const action_event_450_bloc0_1_1_0_2 =  async(event)=>{

                            
                           
              
                          await action_450( { event }) 
              
                          };  

const action_437 =   async ({MailAddresse,Message,setMailAddresse,setMessage})=> {
        try { 
        
      var result =  await api("/ploy4_envoi_formulaire_par_mail_437_0", {body:{MailAddresse,Message},log:true });
       console.log("Result Back", result)  ;
      

window.alert("Message envoyé avec succès!" )
showModalOK();
setMessage({});
}
        catch (error) {
        console.error(error);
        }
      };  

const setMailAddressebloc5_2_4_0 =(value)=> setInternal("MailAddresse",value);

const setMessagebloc5_2_4_0 =(value)=> setInternal("MessageContact",value);

const action_event_437_bloc5_2_4_0 =  async(event)=>{

                            
                           var MailAddresse  ;
var Message = content["MessageContact"];
              
                          await action_437( { MailAddresse,Message,event, setMailAddresse:setMailAddressebloc5_2_4_0 , setMessage:setMessagebloc5_2_4_0  }) 
              
                          };  

//UseEffect




//DIV
  return <><div id='bloc0'  className={ "w-full w-screen bg-white text-black"}     > <div id='bloc0_0'  className={ "relative flex items-end h-80"}     > <img  id='bloc0_0_0' src="https://fs.appisyou.com/apps/aspe/as11718107725265.jpeg" className={ "absolute w-full top-0 left-0 z-10 h-80   object-cover"}     alt="undefined" />
            <div id='bloc0_0_1'  className={ "w-full z-50 relative text-start text-5xl pb-20 text-white pl-16"}     >{`A&S Protection Event`} </div></div>
          <div id='bloc0_1'  className={ "w-full relative centre flex justify-between md:p-8 p-2  items-center justify-center"}     > <div id='bloc0_1_0'  className={ "flex bottom-0 left-0 z-50 p-2 w-screen justify-between md:flex hidden"}     > <img  id='bloc0_1_0_0' src="https://fs.appisyou.com/apps/aspe/aslogo1718107316319.jpeg" className={ "w-16 h-16   object-cover"}     alt="undefined" />
              <div id='bloc0_1_0_1'  className={ "p-2 flex gap-20"}     > <div id='bloc0_1_0_1_0x0'  className={ "text-base flex flex-row items-center justify-center p-1 appbutton"}    onClick = { bloc0_1_0_1_0x0_onClick } > <div id='bloc0_1_0_1_0x0_0'  className={ "p-1 underline"}     >{`home`} </div></div>
        <div id='bloc0_1_0_1_0x1'  className={ "text-base flex flex-row items-center justify-center p-1 appbutton"}    onClick = { bloc0_1_0_1_0x1_onClick } > <div id='bloc0_1_0_1_0x1_0'  className={ "p-1 underline"}     >{`about`} </div></div>
        <div id='bloc0_1_0_1_0x2'  className={ "text-base flex flex-row items-center justify-center p-1 appbutton"}    onClick = { bloc0_1_0_1_0x2_onClick } > <div id='bloc0_1_0_1_0x2_0'  className={ "p-1 underline"}     >{`contact`} </div></div>
        <div id='bloc0_1_0_1_0x3'  className={ "text-base flex flex-row items-center justify-center p-1 appbutton"}    onClick = { bloc0_1_0_1_0x3_onClick } > <div id='bloc0_1_0_1_0x3_0'  className={ "p-1 underline"}     >{`legal`} </div></div></div></div>
            <div id='bloc0_1_1'  className={ "flex bottom-0 left-0 z-50 flex-col w-screen justify-start items-start md:hidden"}     > <div id='bloc0_1_1_0'  className={ "p-2 w-full flex-row justify-between flex"}     > <img  id='bloc0_1_1_0_0' src="https://fs.appisyou.com/apps/aspe/aslogo1718107316319.jpeg" className={ "h-8 w-8   object-cover"}     alt="undefined" />
                <div id='bloc0_1_1_0_1'      onClick = { action_event_449_bloc0_1_1_0_1} > <FontAwesomeIcon  id='bloc0_1_1_0_1_0'  icon={ fa.faAlignJustify}      className={ "flex h-8"}  /></div>
                <div id='bloc0_1_1_0_2'  className={ "hidden"}    onClick = { action_event_450_bloc0_1_1_0_2} > <FontAwesomeIcon  id='bloc0_1_1_0_2_0'  icon={ fa.faClose}      className={ "flex h-8"}  /></div></div>
              <div id='bloc0_1_1_1'  className={ "p-2 w-full flex flex-col items-start hidden"}     > <div id='bloc0_1_1_1_0x0'  className={ "text-base flex flex-row items-center justify-center p-1 appbutton"}    onClick = { bloc0_1_1_1_0x0_onClick } > <div id='bloc0_1_1_1_0x0_0'  className={ "p-1 underline"}     >{`home`} </div></div>
        <div id='bloc0_1_1_1_0x1'  className={ "text-base flex flex-row items-center justify-center p-1 appbutton"}    onClick = { bloc0_1_1_1_0x1_onClick } > <div id='bloc0_1_1_1_0x1_0'  className={ "p-1 underline"}     >{`about`} </div></div>
        <div id='bloc0_1_1_1_0x2'  className={ "text-base flex flex-row items-center justify-center p-1 appbutton"}    onClick = { bloc0_1_1_1_0x2_onClick } > <div id='bloc0_1_1_1_0x2_0'  className={ "p-1 underline"}     >{`contact`} </div></div>
        <div id='bloc0_1_1_1_0x3'  className={ "text-base flex flex-row items-center justify-center p-1 appbutton"}    onClick = { bloc0_1_1_1_0x3_onClick } > <div id='bloc0_1_1_1_0x3_0'  className={ "p-1 underline"}     >{`legal`} </div></div></div></div></div></div><div id='bloc1'  className={ "w-full bg-zinc-100 p-6 flex flex-col justify-center items-start text-zinc-900 py-8"}     > <div id='bloc1_0'  className={ "text-4xl font-bold text-left my-2 px-4"}     >{`A & S Protection Event`} </div>
          <div id='bloc1_1'  className={ "flex flex-col justify-start items-start md:grid grid-cols-3 "}     > <div id='bloc1_1_0'  className={ "flex flex-col items-start p-4"}     > <div id='bloc1_1_0_0'  className={ "font-bold text-lg"}     >{`QUI SOMMES-NOUS ?`} </div>
              <div id='bloc1_1_0_1'  className={ " my-2 text-lg text-left max-w-5/6"}     >{`Utilisez nos templates sur mesure et personnalisables ainsi que des milliers de fonctionnalités de design qui vous permettront de donner vie à vos idées. Vous pouvez également ajouter votre propre code pour un controle total du design de votre site.`} </div></div>
            <div id='bloc1_1_1'  className={ "flex flex-col items-start p-4"}     > <div id='bloc1_1_1_0'  className={ "font-bold text-lg"}     >{`DANS TOUTE LA REGION PARISIENNE`} </div>
              <div id='bloc1_1_1_1'  className={ " my-2 text-lg text-left max-w-5/6"}     >{`Avec A&S Protection Event, vous pouvez créer vous-même un site Web gratuit et professionnel. Vous pouvez commencer avec un modèle design et le personnaliser, ou obtenir un site créé pour vous sur-mesure. Bénéficiez d'une solution tout-en-un : hébergement Web fiable et gratuit, sécurité maximale, référencement puissant et assistance 24h/24.`} </div></div>
            <div id='bloc1_1_2'  className={ "flex flex-col items-start p-4"}     > <div id='bloc1_1_2_0'  className={ "font-bold text-lg"}     >{`DEVIS GRATUIT`} </div>
              <div id='bloc1_1_2_1'  className={ " my-2 text-lg text-left max-w-5/6"}     >{` En créant un site avec notre créateur de site internet, vous profitez de tous les outils nécessaires pour lancer votre acitivité en ligne comme vous le souhaitez.`} </div></div></div>
          <div id='bloc1_2'  className={ " font-bold bg-black text-white text-lg text-left inline p-4 rounded-full px-6 "}     >{`APPELEZ-NOUS AU 06.87.09.76.54`} </div></div><div id='bloc2'  className={ "bg-white items-center flex md:flex-row flex-col"}     > <div id='bloc2_0'  className={ "flex mb-12 justify-center px-3 w-full md:w-1/2"}     > <div id='bloc2_0_0'  className={ "flex flex-col p-20 w-full"}     > <div id='bloc2_0_0_0'  className={ "w-full bg-white text-left font-bold text-4xl"}     >{`Nos qualités`} </div>
              <div id='bloc2_0_0_1'  className={ "text-lg text-justify w-full"}     >{`Nos équipes d'installation, de maintenance et de service après-vente sont formées et spécialisées afin de vous fournir des conseils et un travail de qualité.`} </div></div></div>
          <div id='bloc2_1'  className={ "flex items-center justify-center md:w-1/2 w-full"}     > <img  id='bloc2_1_0' src="https://fs.appisyou.com/apps/aspe/as21718107721343.jpeg" className={ "flex justify-center items-center w-full   object-cover"}     alt="undefined" /></div></div><div id='bloc3'  className={ "bg-white items-center flex md:flex-row flex-col-reverse"}     > <div id='bloc3_0'  className={ "flex items-center justify-center md:w-1/2 w-full"}     > <img  id='bloc3_0_0' src="https://fs.appisyou.com/apps/aspe/as11718107725265.jpeg" className={ "flex justify-center items-center w-full   object-cover"}     alt="undefined" /></div>
          <div id='bloc3_1'  className={ "flex mb-12 justify-center md:w-5/12 sm:w-full px-3"}     > <div id='bloc3_1_0'  className={ "flex flex-col w-full p-20"}     > <div id='bloc3_1_0_0'  className={ "w-full bg-white text-left font-bold text-4xl"}     >{`Formation`} </div>
              <div id='bloc3_1_0_1'  className={ "text-lg text-justify w-full"}     >{`GPS propose des formations sur l’utilisation des produits installés chez nos clients : extincteurs, évacuation, alarme, vidéoprotection, supervision, création de badges…
      
      
      Les formations réalisées par GPS se déroulent chez les clients ou dans nos locaux.`} </div></div></div></div><div id='bloc4'  className={ "bg-white items-center flex md:flex-row flex-col"}     > <div id='bloc4_0'  className={ "flex mb-12 justify-center px-3 w-full md:w-1/2"}     > <div id='bloc4_0_0'  className={ "flex flex-col p-20 w-full"}     > <div id='bloc4_0_0_0'  className={ "w-full bg-white text-left font-bold text-4xl"}     >{`Texte`} </div>
              <div id='bloc4_0_0_1'  className={ "text-lg text-justify w-full"}     >{`GPS dispose d’un service Plans pour la réalisation de vos plans d’intervention incendie, schéma d’implantation anti-intrusion, vidéoprotection, …`} </div></div></div>
          <div id='bloc4_1'  className={ "flex items-center justify-center md:w-1/2 w-full"}     > <img  id='bloc4_1_0' src="https://fs.appisyou.com/users/14/pexels-fauxels1713792803692.jpg" className={ "flex justify-center items-center w-full   object-cover"}     alt="undefined" /></div></div><div id='bloc5'  className={ "p-2 bg-white flex flex-col centre  items-center justify-center"}     > <div id='bloc5_0'  className={ "w-full p-2 bg-white font-medium text-2xl flex flex-col text-center"}     >{`Contact`} </div>
          <div id='bloc5_1'  className={ "p-2 flex justify-center"}     > <div id='bloc5_1_0'  className={ "p-2 bg-white mb-10 w-3/4"}     >{`Nous serions heureux de répondre à tes questions. Tu peux nous contacter via Facebook, Instagram, un petit mail à info@appisyou.io ou via ce formulaire :`} </div></div>
          <div id='bloc5_2'  className={ "p-2 bg-white flex flex-col centre max-w-full p-2 flex flex-col  items-center justify-center"}     > <InputText id={"input_bloc5_2_0"} label={"Nom"} onChange={ (value)=>{
    
    
                      
                          
          setInternal("MessageContact",{...(content["MessageContact"] ?? {} ),"Nom":value})
        
                        
                   } } value={(content[ "MessageContact"] &&  content[ "MessageContact"]["Nom"]) ?? ''} className={"w-full p-2 bg-white"} placeholder={"Nom"} />
            <InputPhoneNumber  id={"input_bloc5_2_1"} label={"Téléphone"} onChange={ (value)=>{
    
    
                      
                          
          setInternal("MessageContact",{...(content["MessageContact"] ?? {} ),"Téléphone":value})
        
                        
                   } } value={(content[ "MessageContact"] &&  content[ "MessageContact"]["Téléphone"]) ?? ''} className={"p-2 flex flex-row justify-start w-full max-w-full"} placeholder={"Téléphone"}  />
            <InputText id={"input_bloc5_2_2"} label={"Adresse Mail"} onChange={ (value)=>{
    
    
                      
                          
          setInternal("MessageContact",{...(content["MessageContact"] ?? {} ),"Adresse Mail":value})
        
                        
                   } } value={(content[ "MessageContact"] &&  content[ "MessageContact"]["Adresse Mail"]) ?? ''} className={"w-full p-2 bg-white"} placeholder={"Adresse Mail"} />
            <InputTextArea id={"input_bloc5_2_3"} label={"Message"} onChange={ (value)=>{
    
    
                      
                          
          setInternal("MessageContact",{...(content["MessageContact"] ?? {} ),"Message":value})
        
                        
                   } } value={(content[ "MessageContact"] &&  content[ "MessageContact"]["Message"]) ?? ''} className={"p-2 flex flex-row justify-start h-40 w-full max-w-full"} placeholder={"Message"}  />
            <div id='bloc5_2_4'  className={ "flex mx-52 p-3 text-gray-400 mb-2 justify-center"}     > <div id='bloc5_2_4_0'  className={ "text-white font-semibold py-2 rounded-2xl px-10 inline-block bg-secondary_color appbutton"}    onClick = { action_event_437_bloc5_2_4_0} >{`ENVOYER`} </div></div></div></div><div id='bloc6'  className={ "w-screen p-4 flex flex-col text-white bg-secondary_color pb-32"}     > <div id='bloc6_0'  className={ "w-full p-2"}     >{`A&S PROTECTION EVENT`} </div>
          <div id='bloc6_1'  className={ "w-full p-2"}     >{`SIRET : XXXXXXXXXXXXXX`} </div>
          <div id='bloc6_2'  className={ "w-full p-2"}     >{`10 RUE DE LA PAIX`} </div>
          <div id='bloc6_3'  className={ "w-full p-2"}     >{`94000 IVRY`} </div>
          <div id='bloc6_4'  className={ "w-full p-2"}     >{`contact@gmail.com`} </div></div></>
  
}

export default Component82Page;
