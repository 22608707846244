// Imports
          import InputText from "../../../../components/inputs/InputText";
import InputTextArea from "../../../../components/inputs/InputTextArea";
import React, { useEffect, useMemo,useContext } from 'react';

import { useState } from 'react';

import '../../../../App.css';
import { useNavigate } from 'react-router-dom';


import * as fa from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

//API import { api } from "../../../../service/mftc-api";


function Component80Page() {
const navigate = useNavigate()


const [content,setContent]= useState( {} )   

const setInternal= (key,value)=>{ setContent(c=> ({...c, [key]:value}) );  }

//States


//Events
const bloc0_1_0_1_0x0_onClick = async (e) => {
                try{ 
                      navigate('/home');
                }
                catch(e){
                  console.log("Error bloc0_1_0_1_0x0_onClick ")
                }  
              };

const bloc0_1_0_1_0x1_onClick = async (e) => {
                try{ 
                      navigate('/about');
                }
                catch(e){
                  console.log("Error bloc0_1_0_1_0x1_onClick ")
                }  
              };

const bloc0_1_0_1_0x2_onClick = async (e) => {
                try{ 
                      navigate('/contact');
                }
                catch(e){
                  console.log("Error bloc0_1_0_1_0x2_onClick ")
                }  
              };

const bloc0_1_0_1_0x3_onClick = async (e) => {
                try{ 
                      navigate('/legal');
                }
                catch(e){
                  console.log("Error bloc0_1_0_1_0x3_onClick ")
                }  
              };

const bloc0_1_1_0_1_onClick = async (e) => {
                try{ 
                     
var obj = e.target.parentNode.nextSibling;
if(obj) {
 if(obj.classList.contains("hidden")){
obj.classList.remove("hidden")
}else
{
obj.classList.add("hidden");
}
}
                }
                catch(e){
                  console.log("Error bloc0_1_1_0_1_onClick")
                }  
              };

const bloc0_1_1_1_0x0_onClick = async (e) => {
                try{ 
                      navigate('/home');
                }
                catch(e){
                  console.log("Error bloc0_1_1_1_0x0_onClick ")
                }  
              };

const bloc0_1_1_1_0x1_onClick = async (e) => {
                try{ 
                      navigate('/about');
                }
                catch(e){
                  console.log("Error bloc0_1_1_1_0x1_onClick ")
                }  
              };

const bloc0_1_1_1_0x2_onClick = async (e) => {
                try{ 
                      navigate('/contact');
                }
                catch(e){
                  console.log("Error bloc0_1_1_1_0x2_onClick ")
                }  
              };

const bloc0_1_1_1_0x3_onClick = async (e) => {
                try{ 
                      navigate('/legal');
                }
                catch(e){
                  console.log("Error bloc0_1_1_1_0x3_onClick ")
                }  
              };

//UseEffect




//DIV
  return <><div id='bloc0'  className={ "w-full bg-black text-white w-screen"}     > <div id='bloc0_0'  className={ "relative flex items-end h-80"}     > <img  id='bloc0_0_0' src="https://fs.appisyou.com/users/11/light-bulbs-12801713868002771.jpg" className={ "absolute w-full top-0 left-0 z-10 h-80   object-cover"}     alt="undefined" />
            <div id='bloc0_0_1'  className={ "w-full z-50 relative text-start text-5xl pb-20 text-white pl-16"}     >{`AppIsYou`} </div></div>
          <div id='bloc0_1'  className={ "w-full relative p-8 centre flex justify-between pr-16  items-center justify-center"}     > <div id='bloc0_1_0'  className={ "flex bottom-0 left-0 z-50 p-2 w-screen justify-between md:flex hidden"}     > <img  id='bloc0_1_0_0' src="https://fs.appisyou.com/users/11/AIY1713792830285.png" className={ "w-16 h-16   object-cover"}     alt="undefined" />
              <div id='bloc0_1_0_1'  className={ "p-2 flex gap-20"}     > <div id='bloc0_1_0_1_0x0'  className={ "text-base flex flex-row items-center justify-center p-1 appbutton"}    onClick = { bloc0_1_0_1_0x0_onClick } > <div id='bloc0_1_0_1_0x0_0'  className={ "p-1 underline"}     >{`home`} </div></div>
        <div id='bloc0_1_0_1_0x1'  className={ "text-base flex flex-row items-center justify-center p-1 appbutton"}    onClick = { bloc0_1_0_1_0x1_onClick } > <div id='bloc0_1_0_1_0x1_0'  className={ "p-1 underline"}     >{`about`} </div></div>
        <div id='bloc0_1_0_1_0x2'  className={ "text-base flex flex-row items-center justify-center p-1 appbutton"}    onClick = { bloc0_1_0_1_0x2_onClick } > <div id='bloc0_1_0_1_0x2_0'  className={ "p-1 underline"}     >{`contact`} </div></div>
        <div id='bloc0_1_0_1_0x3'  className={ "text-base flex flex-row items-center justify-center p-1 appbutton"}    onClick = { bloc0_1_0_1_0x3_onClick } > <div id='bloc0_1_0_1_0x3_0'  className={ "p-1 underline"}     >{`legal`} </div></div></div></div>
            <div id='bloc0_1_1'  className={ "flex bottom-0 left-0 z-50 flex-col w-screen justify-start items-start md:hidden"}     > <div id='bloc0_1_1_0'  className={ "p-2 w-full flex-row justify-between flex"}     > <img  id='bloc0_1_1_0_0' src="https://fs.appisyou.com/users/11/AIY1713792830285.png" className={ "h-8 w-8   object-cover"}     alt="undefined" />
                <FontAwesomeIcon  id='bloc0_1_1_0_1'  icon={ fa.faAlignJustify}     onClick= { bloc0_1_1_0_1_onClick } className={ "flex h-8"}  /></div>
              <div id='bloc0_1_1_1'  className={ "p-2 w-full flex flex-col items-start hidden"}     > <div id='bloc0_1_1_1_0x0'  className={ "text-base flex flex-row items-center justify-center p-1 appbutton"}    onClick = { bloc0_1_1_1_0x0_onClick } > <div id='bloc0_1_1_1_0x0_0'  className={ "p-1 underline"}     >{`home`} </div></div>
        <div id='bloc0_1_1_1_0x1'  className={ "text-base flex flex-row items-center justify-center p-1 appbutton"}    onClick = { bloc0_1_1_1_0x1_onClick } > <div id='bloc0_1_1_1_0x1_0'  className={ "p-1 underline"}     >{`about`} </div></div>
        <div id='bloc0_1_1_1_0x2'  className={ "text-base flex flex-row items-center justify-center p-1 appbutton"}    onClick = { bloc0_1_1_1_0x2_onClick } > <div id='bloc0_1_1_1_0x2_0'  className={ "p-1 underline"}     >{`contact`} </div></div>
        <div id='bloc0_1_1_1_0x3'  className={ "text-base flex flex-row items-center justify-center p-1 appbutton"}    onClick = { bloc0_1_1_1_0x3_onClick } > <div id='bloc0_1_1_1_0x3_0'  className={ "p-1 underline"}     >{`legal`} </div></div></div></div></div></div><div id='bloc1'  className={ "w-screen"}     > <div id='bloc1_0'  className={ "flex flex-row w-screen bg-slate-200"}     > <div id='bloc1_0_0'  className={ "w-full"}     > <div id='bloc1_0_0_0'  className={ "flex flex-col gap-5 text-left w-11/12 py-24 ml-20"}     > <div id='bloc1_0_0_0_0'  className={ "font-semibold italic overline pr-7 text-6xl"}     >{`Talk with our team`} </div>
                <div id='bloc1_0_0_0_1'  className={ "text-xl pr-24"}     >{`Fill out your informations and an AppIsYou team member will reach out to you.`} </div></div></div>
            <div id='bloc1_0_1'  className={ "w-full py-12 pr-14 h-96"}     > <div id='bloc1_0_1_0'  className={ "p-2 bg-white w-auto shadow-2xl drop-shadow-2xl shadow-slate-600 p-2 shadow-slate-600 absolute drop-shadow-2xl shadow-2xl"}     > <div id='bloc1_0_1_0_0'  className={ "p-2 text-start font-bold text-xl"}     >{`Send a message:`} </div>
                <InputText id={"input_bloc1_0_1_0_1"} label={"Nom"} onChange={ (value)=>{
        
        
                          setInternal( "Nom",value)
                       } } value={content[ "Nom"]??''} className={" p-2 "} placeholder={"Nom"} />
                <InputText id={"input_bloc1_0_1_0_2"} label={"Prénom"} onChange={ (value)=>{
        
        
                          setInternal( "Prénom",value)
                       } } value={content[ "Prénom"]??''} className={" p-2 "} placeholder={"Prénom"} />
                <InputText id={"input_bloc1_0_1_0_3"} label={"Adresse Mail"} onChange={ (value)=>{
        
        
                          setInternal( "Adresse Mail",value)
                       } } value={content[ "Adresse Mail"]??''} className={" p-2 "} placeholder={"Adresse Mail"} />
                <InputText id={"input_bloc1_0_1_0_4"} label={"Sujet"} onChange={ (value)=>{
        
        
                          setInternal( "Sujet",value)
                       } } value={content[ "Sujet"]??''} className={" p-2 "} placeholder={"Sujet"} />
                <InputTextArea id={"input_bloc1_0_1_0_5"} label={"Message"} onChange={ (value)=>{
        
        
                          setInternal( "Message",value)
                       } } value={content[ "Message"]??''} className={" p-2 "} placeholder={"Message"}  />
                <div id='bloc1_0_1_0_6'  className={ "p-2 flex flex-row justify-end"}     > <div id='bloc1_0_1_0_6_0'  className={ "rounded-full text-white bg-black mr-4 py-2 px-3"}     >{`Submit`} </div></div>
                <div id='bloc1_0_1_0_7'  className={ " p-2 "}     > </div>
                <div id='bloc1_0_1_0_8'  className={ " p-2 "}     > </div></div></div></div>
          <div id='bloc1_1'  className={ "py-36"}     > </div></div><div id='bloc2'  className={ "w-full p-2 w-screen text-white bg-black"}     > <div id='bloc2_0'  className={ "p-2 flex flex-row items-center justify-between"}     > <div id='bloc2_0_0'  className={ "h-1 border-2 border-zinc-500 w-64"}     > </div>
            <img  id='bloc2_0_1' src="https://fs.appisyou.com/users/11/5365678_fb_facebook_facebook logo_icon1713859080587.png" className={ " p-2    object-cover"}     alt="undefined" />
            <img  id='bloc2_0_2' src="https://fs.appisyou.com/users/11/5296765_camera_instagram_instagram logo_icon1713859189312.png" className={ " p-2    object-cover"}     alt="undefined" />
            <img  id='bloc2_0_3' src="https://fs.appisyou.com/users/11/11244080_x_twitter_twitter new logo_icon1713866393594.png" className={ "p-2   object-cover"}     alt="undefined" />
            <img  id='bloc2_0_4' src="https://fs.appisyou.com/users/11/5296501_linkedin_network_linkedin logo_icon1713859327532.png" className={ " p-2    object-cover"}     alt="undefined" />
            <img  id='bloc2_0_5' src="https://fs.appisyou.com/users/11/2308078_discord_logo_icon1713859341327.png" className={ " p-2    object-cover"}     alt="undefined" />
            <div id='bloc2_0_6'  className={ "h-1 border-2 border-zinc-500 w-64"}     > </div></div></div></>
  
}

export default Component80Page;
