// Imports
          
import React, { useEffect, useMemo,useContext } from 'react';

//setContent import { useState } from 'react';

import '../../../../App.css';
import { useNavigate } from 'react-router-dom';


import * as fa from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

//API import { api } from "../../../../service/mftc-api";


function Component79Page() {
const navigate = useNavigate()


//setContent const [content,setContent]= useState( {} )   

//setContent const setInternal= (key,value)=>{ setContent(c=> ({...c, [key]:value}) );  }

//States


//Events
const bloc0_1_0_1_0x0_onClick = async (e) => {
                try{ 
                      navigate('/home');
                }
                catch(e){
                  console.log("Error bloc0_1_0_1_0x0_onClick ")
                }  
              };

const bloc0_1_0_1_0x1_onClick = async (e) => {
                try{ 
                      navigate('/about');
                }
                catch(e){
                  console.log("Error bloc0_1_0_1_0x1_onClick ")
                }  
              };

const bloc0_1_0_1_0x2_onClick = async (e) => {
                try{ 
                      navigate('/contact');
                }
                catch(e){
                  console.log("Error bloc0_1_0_1_0x2_onClick ")
                }  
              };

const bloc0_1_0_1_0x3_onClick = async (e) => {
                try{ 
                      navigate('/legal');
                }
                catch(e){
                  console.log("Error bloc0_1_0_1_0x3_onClick ")
                }  
              };

const bloc0_1_1_0_1_onClick = async (e) => {
                try{ 
                     
var obj = e.target.parentNode.nextSibling;
if(obj) {
 if(obj.classList.contains("hidden")){
obj.classList.remove("hidden")
}else
{
obj.classList.add("hidden");
}
}
                }
                catch(e){
                  console.log("Error bloc0_1_1_0_1_onClick")
                }  
              };

const bloc0_1_1_1_0x0_onClick = async (e) => {
                try{ 
                      navigate('/home');
                }
                catch(e){
                  console.log("Error bloc0_1_1_1_0x0_onClick ")
                }  
              };

const bloc0_1_1_1_0x1_onClick = async (e) => {
                try{ 
                      navigate('/about');
                }
                catch(e){
                  console.log("Error bloc0_1_1_1_0x1_onClick ")
                }  
              };

const bloc0_1_1_1_0x2_onClick = async (e) => {
                try{ 
                      navigate('/contact');
                }
                catch(e){
                  console.log("Error bloc0_1_1_1_0x2_onClick ")
                }  
              };

const bloc0_1_1_1_0x3_onClick = async (e) => {
                try{ 
                      navigate('/legal');
                }
                catch(e){
                  console.log("Error bloc0_1_1_1_0x3_onClick ")
                }  
              };

//UseEffect




//DIV
  return <><div id='bloc0'  className={ "w-full bg-black text-white w-screen"}     > <div id='bloc0_0'  className={ "relative flex items-end h-80"}     > <img  id='bloc0_0_0' src="https://fs.appisyou.com/users/11/light-bulbs-12801713868002771.jpg" className={ "absolute w-full top-0 left-0 z-10 h-80   object-cover"}     alt="undefined" />
            <div id='bloc0_0_1'  className={ "w-full z-50 relative text-start text-5xl pb-20 text-white pl-16"}     >{`AppIsYou`} </div></div>
          <div id='bloc0_1'  className={ "w-full relative p-8 centre flex justify-between pr-16  items-center justify-center"}     > <div id='bloc0_1_0'  className={ "flex bottom-0 left-0 z-50 p-2 w-screen justify-between md:flex hidden"}     > <img  id='bloc0_1_0_0' src="https://fs.appisyou.com/users/11/AIY1713792830285.png" className={ "w-16 h-16   object-cover"}     alt="undefined" />
              <div id='bloc0_1_0_1'  className={ "p-2 flex gap-20"}     > <div id='bloc0_1_0_1_0x0'  className={ "text-base flex flex-row items-center justify-center p-1 appbutton"}    onClick = { bloc0_1_0_1_0x0_onClick } > <div id='bloc0_1_0_1_0x0_0'  className={ "p-1 underline"}     >{`home`} </div></div>
        <div id='bloc0_1_0_1_0x1'  className={ "text-base flex flex-row items-center justify-center p-1 appbutton"}    onClick = { bloc0_1_0_1_0x1_onClick } > <div id='bloc0_1_0_1_0x1_0'  className={ "p-1 underline"}     >{`about`} </div></div>
        <div id='bloc0_1_0_1_0x2'  className={ "text-base flex flex-row items-center justify-center p-1 appbutton"}    onClick = { bloc0_1_0_1_0x2_onClick } > <div id='bloc0_1_0_1_0x2_0'  className={ "p-1 underline"}     >{`contact`} </div></div>
        <div id='bloc0_1_0_1_0x3'  className={ "text-base flex flex-row items-center justify-center p-1 appbutton"}    onClick = { bloc0_1_0_1_0x3_onClick } > <div id='bloc0_1_0_1_0x3_0'  className={ "p-1 underline"}     >{`legal`} </div></div></div></div>
            <div id='bloc0_1_1'  className={ "flex bottom-0 left-0 z-50 flex-col w-screen justify-start items-start md:hidden"}     > <div id='bloc0_1_1_0'  className={ "p-2 w-full flex-row justify-between flex"}     > <img  id='bloc0_1_1_0_0' src="https://fs.appisyou.com/users/11/AIY1713792830285.png" className={ "h-8 w-8   object-cover"}     alt="undefined" />
                <FontAwesomeIcon  id='bloc0_1_1_0_1'  icon={ fa.faAlignJustify}     onClick= { bloc0_1_1_0_1_onClick } className={ "flex h-8"}  /></div>
              <div id='bloc0_1_1_1'  className={ "p-2 w-full flex flex-col items-start hidden"}     > <div id='bloc0_1_1_1_0x0'  className={ "text-base flex flex-row items-center justify-center p-1 appbutton"}    onClick = { bloc0_1_1_1_0x0_onClick } > <div id='bloc0_1_1_1_0x0_0'  className={ "p-1 underline"}     >{`home`} </div></div>
        <div id='bloc0_1_1_1_0x1'  className={ "text-base flex flex-row items-center justify-center p-1 appbutton"}    onClick = { bloc0_1_1_1_0x1_onClick } > <div id='bloc0_1_1_1_0x1_0'  className={ "p-1 underline"}     >{`about`} </div></div>
        <div id='bloc0_1_1_1_0x2'  className={ "text-base flex flex-row items-center justify-center p-1 appbutton"}    onClick = { bloc0_1_1_1_0x2_onClick } > <div id='bloc0_1_1_1_0x2_0'  className={ "p-1 underline"}     >{`contact`} </div></div>
        <div id='bloc0_1_1_1_0x3'  className={ "text-base flex flex-row items-center justify-center p-1 appbutton"}    onClick = { bloc0_1_1_1_0x3_onClick } > <div id='bloc0_1_1_1_0x3_0'  className={ "p-1 underline"}     >{`legal`} </div></div></div></div></div></div><div id='bloc1'  className={ "p-4 flex-col flex centre w-screen pb-10 bg-slate-200  items-center justify-center"}     > <FontAwesomeIcon  id='bloc1_0'  icon={ fa.faStar}      className={ "w-20 h-20 p-4"}  />
          <div id='bloc1_1'  className={ "text-3xl p-1"}     >{`About Us`} </div>
          <div id='bloc1_2'  className={ "w-96"}     >{`At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus, omnis voluptas assumenda est, omnis dolor repellendus. Temporibus autem quibusdam et aut officiis debitis aut rerum necessitatibus saepe eveniet ut et voluptates repudiandae sint et molestiae non recusandae. Itaque earum rerum hic tenetur a sapiente delectus, ut aut reiciendis voluptatibus maiores alias consequatur aut perferendis doloribus asperiores repellat.`} </div></div><div id='bloc2'  className={ "w-screen p-6 bg-slate-50"}     > <div id='bloc2_0'  className={ "p-2 font-semibold overline pt-16 italic text-4xl"}     >{`Meet the team:`} </div>
          <div id='bloc2_1'  className={ "flex flex-row flex-wrap py-12 justify-center gap-20 px-20"}     > <div id='bloc2_1_0x0'  className={ "w-96 flex flex-row justify-start items-center gap-2 p-3 rounded-full text-white bg-orange-700"}     > <img  id='bloc2_1_0x0_0' src="https://fs.appisyou.com/apps/pat/phil1713873782482.png" className={ "w-20 h-20 rounded-full   object-cover"}     alt="undefined" />
              <div id='bloc2_1_0x0_1'  className={ "text-start"}     > <div id='bloc2_1_0x0_1_0'       >{`Phil`} </div>
                <div id='bloc2_1_0x0_1_1'  className={ "text-xs"}     >{`Lead Dev`} </div></div></div>
    <div id='bloc2_1_0x1'  className={ "w-96 flex flex-row justify-start items-center gap-2 p-3 rounded-full text-white bg-orange-700"}     > <img  id='bloc2_1_0x1_0' src="https://fs.appisyou.com/apps/pat/mike1713873552091.png" className={ "w-20 h-20 rounded-full   object-cover"}     alt="undefined" />
              <div id='bloc2_1_0x1_1'  className={ "text-start"}     > <div id='bloc2_1_0x1_1_0'       >{`Mike`} </div>
                <div id='bloc2_1_0x1_1_1'  className={ "text-xs"}     >{`Fullstack Dev`} </div></div></div>
    <div id='bloc2_1_0x2'  className={ "w-96 flex flex-row justify-start items-center gap-2 p-3 rounded-full text-white bg-orange-700"}     > <img  id='bloc2_1_0x2_0' src="https://fs.appisyou.com/apps/pat/anna1713873627899.png" className={ "w-20 h-20 rounded-full   object-cover"}     alt="undefined" />
              <div id='bloc2_1_0x2_1'  className={ "text-start"}     > <div id='bloc2_1_0x2_1_0'       >{`Anna`} </div>
                <div id='bloc2_1_0x2_1_1'  className={ "text-xs"}     >{`Frontend Dev`} </div></div></div>
    <div id='bloc2_1_0x3'  className={ "w-96 flex flex-row justify-start items-center gap-2 p-3 rounded-full text-white bg-orange-700"}     > <img  id='bloc2_1_0x3_0' src="https://fs.appisyou.com/apps/pat/benji1713873685247.png" className={ "w-20 h-20 rounded-full   object-cover"}     alt="undefined" />
              <div id='bloc2_1_0x3_1'  className={ "text-start"}     > <div id='bloc2_1_0x3_1_0'       >{`Benji`} </div>
                <div id='bloc2_1_0x3_1_1'  className={ "text-xs"}     >{`Fullstack Dev`} </div></div></div>
    <div id='bloc2_1_0x4'  className={ "w-96 flex flex-row justify-start items-center gap-2 p-3 rounded-full text-white bg-orange-700"}     > <img  id='bloc2_1_0x4_0' src="https://fs.appisyou.com/apps/pat/willy1713873520964.png" className={ "w-20 h-20 rounded-full   object-cover"}     alt="undefined" />
              <div id='bloc2_1_0x4_1'  className={ "text-start"}     > <div id='bloc2_1_0x4_1_0'       >{`Willy`} </div>
                <div id='bloc2_1_0x4_1_1'  className={ "text-xs"}     >{`Frontend Dev`} </div></div></div>
    <div id='bloc2_1_0x5'  className={ "w-96 flex flex-row justify-start items-center gap-2 p-3 rounded-full text-white bg-orange-700"}     > <img  id='bloc2_1_0x5_0' src="https://fs.appisyou.com/apps/pat/IMG_20240302_230131_6551713175394980.jpg" className={ "w-20 h-20 rounded-full   object-cover"}     alt="undefined" />
              <div id='bloc2_1_0x5_1'  className={ "text-start"}     > <div id='bloc2_1_0x5_1_0'       >{`Pat`} </div>
                <div id='bloc2_1_0x5_1_1'  className={ "text-xs"}     >{`Frontend Dev`} </div></div></div></div></div><div id='bloc3'  className={ "w-full p-2 w-screen text-white bg-black"}     > <div id='bloc3_0'  className={ "p-2 flex flex-row items-center justify-between"}     > <div id='bloc3_0_0'  className={ "h-1 border-2 border-zinc-500 w-64"}     > </div>
            <img  id='bloc3_0_1' src="https://fs.appisyou.com/users/11/5365678_fb_facebook_facebook logo_icon1713859080587.png" className={ " p-2    object-cover"}     alt="undefined" />
            <img  id='bloc3_0_2' src="https://fs.appisyou.com/users/11/5296765_camera_instagram_instagram logo_icon1713859189312.png" className={ " p-2    object-cover"}     alt="undefined" />
            <img  id='bloc3_0_3' src="https://fs.appisyou.com/users/11/11244080_x_twitter_twitter new logo_icon1713866393594.png" className={ "p-2   object-cover"}     alt="undefined" />
            <img  id='bloc3_0_4' src="https://fs.appisyou.com/users/11/5296501_linkedin_network_linkedin logo_icon1713859327532.png" className={ " p-2    object-cover"}     alt="undefined" />
            <img  id='bloc3_0_5' src="https://fs.appisyou.com/users/11/2308078_discord_logo_icon1713859341327.png" className={ " p-2    object-cover"}     alt="undefined" />
            <div id='bloc3_0_6'  className={ "h-1 border-2 border-zinc-500 w-64"}     > </div></div></div></>
  
}

export default Component79Page;
